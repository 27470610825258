import React, {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom";
// import useAuthUserTokenRequest from '../hooks/user/useAuthUserTokenRequest';
import userContext from "../userStore/context";
import { getFromStorage } from "../utilis/localStorage";
import useGetInfoHook from "./hooks/useGetUserInfo";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { LogoutOutlined } from "@ant-design/icons";
import {
  OverViewIcon,
  OverViewActive,
  LogsIcon,
  LogsActiveIcon,
  BusinessIcon,
  BusinessActiveIcon,
  SellersTabIcon,
  BuyersTabIcon,
  CompaniesTabIcon,
  AdminsTabIcon,
} from "../assets/sidebarIcons";
import ExitsLogo from "../assets/ExitsLogoSvg.svg";
import AlarmButton from "./notifications";
// import { ELBROKERS_LOGO, ELBROKERS_LOGO_COLLAPSED } from '../config';
// const userNavigation = [{ name: "Sign out", href: "#" }];

const AppLayout: React.FC = ({ children }): JSX.Element => {
  // useAuthUserTokenRequest();
  // getUserData();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { userState: state, setUserContext } = useContext(userContext);
  const [navActive, setNavActive] = useState({
    name: "Overview",
    href: "#",
    icon: OverViewIcon,
    current: true,
  });
  const [navigation, setNavigation] = useState([
    {
      name: "Overview",
      href: "#",
      icon: OverViewIcon,
      iconActive: OverViewActive,
      current: true,
    },
    {
      name: "Companies",
      href: "#",
      icon: CompaniesTabIcon,
      iconActive: CompaniesTabIcon,
      current: false,
    },

    {
      name: "Sellers",
      href: "#",
      icon: SellersTabIcon,
      iconActive: SellersTabIcon,
      current: false,
    },
    {
      name: "Buyers",
      href: "#",
      icon: BuyersTabIcon,
      iconActive: BuyersTabIcon,
      current: false,
    },
    {
      name: "Admins",
      href: "#",
      icon: AdminsTabIcon,
      iconActive: AdminsTabIcon,
      current: false,
    },
    {
      name: "Advisory",
      href: "#",
      icon: AdminsTabIcon,
      iconActive: AdminsTabIcon,
      current: false,
    },
  ]);

  const {
    push,
    location: { pathname },
  } = useHistory();

  const { state: initState, logoutHandler } = useGetInfoHook();

  const handleChangeNav = useCallback(
    (item) => {
      setNavActive({ ...item, current: true });
      const newArr = navigation.map((oneItem) => {
        if (oneItem.name === item.name) {
          return { ...oneItem, current: true };
        }
        return { ...oneItem, current: false };
      });
      setNavigation(newArr);
      push(`../${item.name}`);
    },
    [navigation, push]
  );

  // change active nav according to pathname
  useEffect(() => {
    navigation.map((item) => {
      if (pathname.includes(item.name)) {
        const newArr = navigation.map((oneItem) => {
          if (oneItem.name === item.name) {
            return { ...oneItem, current: true };
          }
          return { ...oneItem, current: false };
        });
        setNavigation(newArr);
      }
    });
  }, [pathname]);

  const handleDrawNav = useMemo(() => {
    return (
      <div className="">
        {navigation.map((item: any) => (
          <div
            key={item.name}
            onClick={() => handleChangeNav(item)}
            className={classNames(
              item.current
                ? "bg-[#F3F6FC1F] text-white"
                : "text-white hover:bg-[#F3F6FC1F] hover:text-white",
              "group flex items-center  py-2 text-sm font-medium cursor-pointer px-[11px]"
            )}
          >
            {/* <div className="w-6">
              <div className={item.current ? "flex" : "hidden"}>
                {item.current && <CurrentItemIcon />}
              </div>
            </div> */}

            <item.icon
              //@ts-ignore
              className={classNames(
                item.current
                  ? "text-gray-300"
                  : "text-gray-400 group-hover:text-gray-300",
                "mr-3 flex-shrink-0 h-6 w-6"
              )}
              aria-hidden="true"
            />
            {/* <RetailIcon /> */}
            <div className="w-5" />
            {item.name}
          </div>
        ))}
      </div>
    );
  }, [navigation, handleChangeNav]);

  const handleUserData = useCallback(() => {
    const User = getFromStorage("@userAdminExits");
    if (User && !!Object.keys(User).length) {
      // @ts-ignore
      setUserContext(User);
      // handleSidebarResponsive();
    } else {
      // if ("Notification" in window) firebase.messaging().deleteToken();
      return push("/signin");
    }
  }, [setUserContext, push]);
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(
    () => {
      handleUserData();
    },
    // eslint-disable-next-line
    []
  );

  const handleLayoutView = useMemo(() => {
    return (
      <div className={"w-screen max-w-screen min-h-screen max-h-max bg-[#fff]"}>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-[#0040AC]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex justify-center items-center px-4 w-full">
                  <img className="h-8 w-auto" src={ExitsLogo} alt="Workflow" />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className=" space-y-1">
                    {navigation.map((item: any) => (
                      <div
                        key={item.name}
                        onClick={() => {
                          handleChangeNav(item);
                          setSidebarOpen(false);
                        }}
                        className={classNames(
                          item.current
                            ? "bg-[#F3F6FC1F] text-white"
                            : "text-gray-300 hover:bg-[#F3F6FC1F] hover:text-white",
                          "group flex items-center py-2 text-base font-medium rounded-md px-[11px]"
                        )}
                      >
                        {/* <div className="w-6">
                      <div className={item.current ? "flex" : "hidden"}>
                        {item.current && <CurrentItemIcon />}
                      </div>
                    </div> */}

                        <item.icon
                          //@ts-ignore
                          className={classNames(
                            item.current
                              ? "text-gray-300"
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <div className="w-5" />
                        {item.name}
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 z-20">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-[#0040AC] w-full">
            <div className="flex-1  flex flex-col mt-5 overflow-y-auto">
              <img
                className="mx-auto w-1[40px] h-[40px]"
                src={ExitsLogo}
                alt="Workflow"
              />
              <nav className="flex-1  py-4 space-y-1">{handleDrawNav}</nav>
            </div>
          </div>
        </div>
        <div className=" flex flex-col w-full h-full">
          <div className="sticky top-0 z-10 flex items-center h-16 max-w-screen bg-[#fff] ">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className=" md:ml-64 flex-1 px-8 flex justify-between bg-[#fff]">
              <div className="flex-1 flex"></div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-4 relative">
                  <div style={{ display: "flex" }}>
                    <AlarmButton />
                    <Menu.Button
                      onClick={logoutHandler}
                      className="max-w-xs  flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">Open user menu</span>
                      <LogoutOutlined className="text-black text-[2rem]" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 md:ml-64  h-full  py-6  ">{children}</main>
        </div>
      </div>
    );
  }, [children, sidebarOpen, navActive, navigation]);

  return <>{handleLayoutView}</>;
};
export default AppLayout;
