//@ts-nocheck
import { message } from "antd";
import { Del, get, post, put, patch } from "../../utilis/requests";
import { format } from "date-fns";

export const getCompaniesHandler = async (
  api: String,
  pageNum: Number,
  token,
  status?: String
) => {
  try {
    const res = await get(`/${api}?status=${status}&page=${pageNum}`, token);
    return res.data;
  } catch (error) {
    message.error("error");
    return { data: [], noOfItems: 0 };
  }
};

//// fetch Sellers / Buyers
export const getHandler = async (
  api: String,
  pageNum: Number,
  token,
  extraSearch?: string,
  param?: string
) => {
  try {
    const searchToBeUsed =
      typeof extraSearch === "string"
        ? extraSearch
        : format(new Date(extraSearch || 0), "yyyy-MM-dd'T'HH:mm:ss'Z'");
    const delimiter = api.includes("?") ? "&" : "?";

    const res = await get(
      `/${api}${delimiter}page=${pageNum}&${
        searchToBeUsed && param ? param + "=" + searchToBeUsed : ""
      }`,
      token
    );
    return res.data;
  } catch (error) {
    message.error("error");
    return { data: [], noOfItems: 0 };
  }
};

// get request without pagination
export const getHandlerNoPage = async (api: String, token) => {
  try {
    const res = await get(`/${api}`, token);
    return res.data;
  } catch (error) {
    message.error("error");
    return { data: [], noOfItems: 0 };
  }
};

export const deleteHandler = async (api: String, id: any, token) => {
  try {
    await Del(`/${api}/${id}`, token);
    message.success("done");
    return true;
  } catch (error) {
    message.error("error");
    return false;
  }
};

export const addHandler = async (api: String, body: any, token) => {
  try {
    let response = await post(`/${api}`, body, token);
    message.success(response.message);
    return true;
  } catch (e) {
    message.error(e.response?.data?.message);
    return false;
  }
};
export const patchStatusCompany = async (api: String, body: any, token) => {
  try {
    let response = await put(`/${api}`, body, token);
    message.success(response.message);
    return true;
  } catch (e) {
    message.error(e.response?.data?.message);
    return false;
  }
};

export const patchCompanyInfo = async (api: String, body: any, token) => {
  try {
    let response = await put(`/${api}`, body, token);
    message.success(response.message);
    return response;
  } catch (e) {
    message.error(e.response?.data?.message);
    return response;
  }
};

// open item by Id
export const openItemById = async (api: String, id: string, token: string) => {
  try {
    const res = await get(`/${api}?_id=${id}`, token);
    return res.data;
  } catch (error) {
    message.error("error");
    return { data: [], noOfItems: 0 };
  }
};

export const editHandler = async (
  api: String,
  body: any,
  token: string,
  id?: string
) => {
  try {
    let response = await patch(`/${api}/${id}`, body, token);
    message.success(response?.data?.message);
    return true;
  } catch (e: any) {
    message.error(e.response?.data?.message);
    console.log(e);
    return false;
  }
};


export const editPutHandler = async (
  api: String,
  body: any,
  token: string,
  id?: string
) => {
  try {
    let response = await put(`/${api}/${id}`, body, token);
    message.success(response?.data?.message);
    return true;
  } catch (e: any) {
    message.error(e.response?.data?.message);
    console.log(e);
    return false;
  }
};
